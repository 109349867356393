import {parse} from 'accept-language-parser';
import type {IncomingHttpHeaders} from 'node:http';

import {isClient, isServer} from 'source/utilities/guards/environment';
import {ELanguage} from 'source/utilities/language';

import {CookieNames, getCookie} from 'source/utilities/network/cookies';

type MimeKind = 'json' | 'html';

export const mime = (type: MimeKind) =>
  ({
    html: 'plain/html',
    json: 'application/json',
  })[type];

export const formatToken = (token: string | undefined) =>
  token ? ['Bearer', token].join(' ') : '';

export const serverHeaders = (
  initHeaders: IncomingHttpHeaders = {},
  cookies: Partial<{[key: string]: string}> = {},
) => {
  if (!isServer()) {
    throw new Error(
      'Function was called in wrong environment, ensure you are in nodejs process!',
    );
  }

  const language =
    parse(initHeaders['accept-language'])[0]?.code ?? ELanguage.RU;
  const token = formatToken(cookies[CookieNames.HOTELIER_AUTHORIZATION]);
  const temporaryToken = cookies[CookieNames.TEMPORARY_AUTHORIZATION];

  const headers = new Headers([
    ['accept', mime('json')],
    ['accept-language', language],
    ['authorization', token],
    ['content-type', 'application/json'],
  ]);

  if (temporaryToken && temporaryToken.length > 0) {
    headers.delete('authorization');
    headers.set('x-access-token', temporaryToken);
  }

  return headers;
};

export const clientHeaders = (initHeaders: IncomingHttpHeaders = {}) => {
  if (!isClient()) {
    throw new Error(
      'Function was called in wrong environment, ensure you are in browser process!',
    );
  }
  const parsedLanguageArray = parse(window.navigator.language);
  const language = parsedLanguageArray[0]?.code ?? ELanguage.RU;
  const token = formatToken(getCookie(CookieNames.HOTELIER_AUTHORIZATION));
  const temporaryToken = getCookie(CookieNames.TEMPORARY_AUTHORIZATION);

  const content = initHeaders['content-type'] ?? mime('json');

  const headers = new Headers([
    ['accept', mime('json')],
    ['accept-language', language],
    ['authorization', token],
    ['content-type', content],
  ]);

  if (temporaryToken && temporaryToken.length > 0) {
    headers.delete('authorization');
    headers.set('x-access-token', temporaryToken);
  }

  return headers;
};

export const uppyHeaders = () => {
  if (!isClient()) {
    throw new Error(
      'Function was called in wrong environment, ensure you are in browser process!',
    );
  }
  const token = formatToken(getCookie(CookieNames.HOTELIER_AUTHORIZATION));
  const parsedLanguageArray = parse(window.navigator.language);
  const language = parsedLanguageArray[0]?.code ?? ELanguage.RU;
  const temporaryToken = getCookie(CookieNames.TEMPORARY_AUTHORIZATION);

  const headers = new Map([
    ['accept', mime('json')],
    ['accept-language', language],
    ['authorization', token],
  ]);

  if (temporaryToken && temporaryToken.length > 0) {
    headers.set('x-access-token', temporaryToken);
    headers.delete('authorization');
  }

  return Object.fromEntries(headers.entries());
};
