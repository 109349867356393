import styled from '@emotion/styled';
import {Button} from 'library/components/button';
import {Input} from 'library/components/input';
import {LabeledComponent} from 'library/components/labeled-component';
import {Text} from 'library/components/text';
import useTranslation from 'next-translate/useTranslation';
import {Dispatch, FC, useContext, useState} from 'react';
import {CHANNEL_MANAGERS_OPTIONS} from 'slices/integration/lib/constants';
import {EIntegrations} from 'slices/integration/lib/types';
import {HotelContext} from 'source/context/hotel';
import {showSuccessMessage} from 'source/utilities/exceptions/business';
import {theme} from 'source/utilities/global-style';

const Wrapper = styled.div`
  max-width: 440px;
`;

const TokenInputWrapper = styled.div``;

const TokenInput = styled(Input)`
  cursor: pointer;
`;

const ActionsWrapper = styled.div``;

const StyledButton = styled(Button)`
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  margin-top: 20px;
  width: 200px;
  padding: 15px;
`;

const Label = styled(LabeledComponent)`
  margin-bottom: 26px;
`;

interface IProps {
  token: string;
  loading: boolean;
  setLoading: Dispatch<boolean>;
}

export const BnovoIntegrationContent: FC<IProps> = ({
  loading,
  setLoading,
  token,
}) => {
  const {t} = useTranslation('integration');
  const [hotel, setHotel] = useContext(HotelContext);
  const [hasCopy, setHasCopy] = useState(false);

  const bnovoIntegration = hotel?.bnovo_integration;
  const bnovoIntegrationOption = CHANNEL_MANAGERS_OPTIONS.find(
    ({value}) => value === EIntegrations.BNOVO,
  )!;

  const handleCopy = () => {
    if (!hasCopy) {
      navigator.clipboard
        .writeText(token)
        .then(() => {
          setHasCopy(true);
          showSuccessMessage(
            t('success_token_copy', {
              providerName: bnovoIntegrationOption.label,
            }),
          );
          setTimeout(() => setHasCopy(false), 5000);
        })
        .catch(() => {
          setHasCopy(false);
          showSuccessMessage(t('attemption_error_token_copy'));
        });
    }
  };

  if (bnovoIntegration?.is_configured) {
    return (
      <Wrapper>
        <TokenInputWrapper>
          {bnovoIntegration?.is_ready ? (
            <Text size="S">
              {t('success_integration', {
                providerName: bnovoIntegrationOption.label,
              })}
            </Text>
          ) : (
            <Text size="S">
              {t('integration_in_process', {
                providerName: bnovoIntegrationOption.label,
              })}
            </Text>
          )}
        </TokenInputWrapper>
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      <TokenInputWrapper>
        {token && (
          <Label
            gap={11}
            size="S"
            color={theme.palette.fontSecondary}
            text={t('token_input.title')}
          >
            <TokenInput
              type="text"
              value={token}
              icon={hasCopy ? 'file-uploaded-check' : 'copy'}
              iconOnClick={handleCopy}
            />
          </Label>
        )}
      </TokenInputWrapper>
      {/* TODO 09.02.24 Вернуть когда скажет Тимур */}
      {/* <ActionsWrapper> */}
      {/*  {bnovoIntegration?.can_be_disabled && token && ( */}
      {/*    <StyledButton */}
      {/*      onClick={() => { */}
      {/*        if (hotel?.id) { */}
      {/*          handleDisableIntegration( */}
      {/*            hotel.id, */}
      {/*            bnovoIntegrationOption, */}
      {/*            t, */}
      {/*            setLoading, */}
      {/*            setHotel, */}
      {/*          ); */}
      {/*        } */}
      {/*      }} */}
      {/*    > */}
      {/*      {loading ? ( */}
      {/*        <CircularLoader variant="thin" size={20} /> */}
      {/*      ) : ( */}
      {/*        t('actions.disable_integration.label') */}
      {/*      )} */}
      {/*    </StyledButton> */}
      {/*  )} */}
      {/* </ActionsWrapper> */}
    </Wrapper>
  );
};
