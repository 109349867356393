import {request} from 'library/api';
import {path} from 'ramda';
import {url} from 'source/utilities/network/url';
import {
  UserMePartialUpdateResponse,
  UserMePartialUpdateRequest,
} from 'types/api-scheme';
import {clientHeaders} from 'source/utilities/network/http';

export type EditUserContext = {
  payload: UserMePartialUpdateRequest;
};

const editUser = ({payload}: EditUserContext) =>
  request<UserMePartialUpdateResponse>(url('user/me'), {
    method: 'PATCH',
    headers: clientHeaders(),
    body: JSON.stringify(payload),
  }).then(path<UserMePartialUpdateResponse['data']>([1, 'data']));

export const userApi = {
  edit: editUser,
};
