import styled from '@emotion/styled';
import {FC, useEffect} from 'react';
import {reset, setNotificationChannels} from 'slices/profile/store';
import NotificationChannels from 'slices/profile/ui/notification-channels';
import SocialSubscribe from 'slices/profile/ui/social-subscribe';
import {useAppDispatch} from 'source/store';
import ChangePasswordForm from './ui/change-password-form/change-password-form';
import ProfileInfo from './ui/profile-info';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

interface Props {
  notificationChannels: NotificationChannels;
}

const Content: FC<Props> = ({
  notificationChannels: serverNotificationChannels,
}) => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (serverNotificationChannels) {
      dispatch(setNotificationChannels(serverNotificationChannels));
    }

    return () => {
      dispatch(reset());
    };
  }, []);

  return (
    <Wrapper>
      <ProfileInfo />
      <SocialSubscribe />
      <NotificationChannels />
      <ChangePasswordForm />
    </Wrapper>
  );
};

export default Content;
