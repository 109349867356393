import {css} from '@emotion/react';
import styled from '@emotion/styled';
import {ArrowIcon} from 'library/components/arrow-link/arrow';
import {Checkbox} from 'library/components/checkbox';
import {Input} from 'library/components/input';
import {Option} from 'library/components/select';
import useTranslation from 'next-translate/useTranslation';
import {FC, memo, useEffect, useRef, useState} from 'react';

const Wrapper = styled.div`
  position: relative;
  cursor: pointer;
`;

const InputWrapper = styled.div`
  position: relative;
  z-index: 1;
`;

const StyledInput = styled(Input)`
  & input {
    user-select: none;
    pointer-events: none;
    padding-right: 30px;
  }
`;

const Arrow = styled(ArrowIcon)<{isOpen: boolean; disabled?: boolean}>`
  transform: ${({isOpen}) => (isOpen ? 'rotate(180deg)' : 'unset')};
  transition: 300ms;
  position: absolute;
  right: 12px;
  top: 45%;
  ${({disabled}) =>
    disabled &&
    css`
      cursor: not-allowed;
      opacity: 0.4;
    `}
`;

const List = styled.ul`
  margin-top: 8px;
  width: 100%;
  max-height: 40vh;
  border: 1px solid ${({theme}) => theme.palette.activeBackground}
  border-radius: 6px;
  background-color: ${({theme}) => theme.palette.defaultBackground};
  list-style: none;
  overflow: auto;
  position: absolute;
  top: 100%;
  z-index: 5;
`;

const ListItem = styled(Checkbox)`
  padding: 4px 8px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  gap: 8px;
  border-radius: 6px;
  background-color: transparent;
  cursor: pointer;
  transition: background-color 200ms ease-in-out;

  :hover {
    background-color: ${({theme}) => theme.palette.darkerBackground};
  }
`;

const ListItemMemo = memo(ListItem, (previousProps, nextProps) => {
  const keys: (keyof typeof previousProps)[] = ['value', 'label'];

  const equals = (key: keyof typeof previousProps) =>
    previousProps[key] === nextProps[key];

  return keys.every(equals);
});

interface Props {
  values: Option[];
  options: Option[];
  /**
   * Данная функция мемоизируется в ListItemMemo, и не обновляется, иметь ввиду при передачи параметров в неё из верхнего слоя компонента
   * @param flag - Флаг который говорит о включении, и выключении
   * @param option - Объект который хранит в себе value, label
   */
  setValue: (flag: boolean, option: Option) => void;
  disabled?: boolean;
  className?: string;
}

export const SelectCheckbox: FC<Props> = ({
  disabled,
  values,
  setValue,
  options,
  className,
}) => {
  const {t} = useTranslation();

  const ref = useRef<HTMLDivElement>(null);
  const listRef = useRef<HTMLUListElement>(null);

  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref]);

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    if (!disabled && options.length > 0) {
      setIsOpen((previous) => !previous);
    }

    event.stopPropagation();
    event.preventDefault();
  };

  return (
    <Wrapper ref={ref} className={className}>
      <InputWrapper onClick={handleClick}>
        <StyledInput
          placeholder=""
          label=""
          type="text"
          disabled={disabled}
          cursor="pointer"
          autoComplete="off"
          value={values.map((value) => value.label).join(', ')}
        />
        <Arrow disabled={disabled} isOpen={isOpen} />
      </InputWrapper>
      {isOpen && (
        <List ref={listRef}>
          {options.map((option) => {
            return (
              <ListItemMemo
                key={`${option.value}-${option.label}`}
                value={values.some(
                  (valuesOption) => valuesOption.value === option.value,
                )}
                label={t(option?.label)}
                onChange={(flag) => {
                  setValue(flag, option);
                }}
              />
            );
          })}
        </List>
      )}
    </Wrapper>
  );
};
