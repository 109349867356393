import styled from '@emotion/styled';
import {ArrowText} from 'library/components/arrow-text';
import {NoContent} from 'library/components/no-content';
import useTranslation from 'next-translate/useTranslation';
import {useRouter} from 'next/router';
import {isEmpty} from 'ramda';
import {useContext, useEffect, useState} from 'react';
import {selectRooms} from 'slices/rooms/store';
import BnovoProvider from 'source/components/bnovo-provider';
import {RealtyProvider} from 'source/components/realty-provider';
import {HotelContext} from 'source/context/hotel';
import {store, useAppSelector} from 'source/store';
import {useDeviceDetection} from 'source/utilities/hooks/use-device-detection';
import {useBnovoIntegration} from 'source/utilities/hooks/use-is-bnovo-integration';
import {useRealtyIntegration} from 'source/utilities/hooks/use-is-realty-integration';
import {parseBooleanQueryParameter} from 'source/utilities/network/router';
import {subscribeUpdateRateObject} from './lib/helpers';
import Conditions from './ui/conditions';
import HotelServices from './ui/hotel-services';
import InformationAboutRate from './ui/information-about-rate';
import Limitations from './ui/limitations';
import RateTable from './ui/rate-table';

const Wrapper = styled.div`
  margin-top: 40px;
  padding-bottom: 40px;
`;

const DropdownWrapper = styled.div`
  margin-bottom: 20px;
`;

const ChildrenWrapper = styled.div`
  padding: 23px 0px 23px 0px;
`;

const Content = () => {
  const router = useRouter();
  const isNewRate = parseBooleanQueryParameter(router.query.isNew);
  const roomList = useAppSelector(selectRooms);

  const [aboutRateOpen, setAboutRateOpen] = useState(isNewRate);
  const [conditionsOpen, setConditionsOpen] = useState(isNewRate);

  const [hasBnovoIntegration] = useBnovoIntegration();
  const [hasRealtyIntegration] = useRealtyIntegration();
  const hasAnyIntegration = hasBnovoIntegration || hasRealtyIntegration;

  const {t} = useTranslation('rate');
  const [hotel] = useContext(HotelContext);
  const mobile = useDeviceDetection('mobile');

  const textSize = mobile ? 'S' : 'L';

  useEffect(() => {
    const unsubscribe = store.subscribe(() => subscribeUpdateRateObject());
    return unsubscribe;
  }, []);

  if (isEmpty(roomList)) {
    return (
      <NoContent
        buttonLabel={t('no_content.add_room_label')}
        subtitle={t('no_content.add_room_subtitle')}
        href={`/manage/${hotel?.id}/rooms`}
      />
    );
  }

  return (
    <Wrapper>
      <DropdownWrapper>
        <ArrowText
          title={t('information_about_rate_title')}
          size={textSize}
          parentState={aboutRateOpen}
          parentHandler={setAboutRateOpen}
        >
          <ChildrenWrapper>
            <InformationAboutRate />
          </ChildrenWrapper>
        </ArrowText>
      </DropdownWrapper>
      <DropdownWrapper>
        <ArrowText
          title={t('conditions_title')}
          size={textSize}
          parentState={conditionsOpen}
          parentHandler={setConditionsOpen}
        >
          <ChildrenWrapper>
            <Conditions />
          </ChildrenWrapper>
        </ArrowText>
      </DropdownWrapper>
      {/*
        -- TODO 26.06.23, включить когда скажут
      */}
      {/* {hasRealtyIntegration && ( */}
      {/*  <DropdownWrapper> */}
      {/*    <ArrowText */}
      {/*      title={t('integration_guest_price_table_title')} */}
      {/*      size={textSize} */}
      {/*    > */}
      {/*      <ChildrenWrapper> */}
      {/*        <IntegrationGuestPriceTable /> */}
      {/*      </ChildrenWrapper> */}
      {/*    </ArrowText> */}
      {/*  </DropdownWrapper> */}
      {/* )} */}

      <DropdownWrapper>
        <ArrowText title={t('availability_restrictions_title')} size={textSize}>
          <ChildrenWrapper>
            <BnovoProvider>
              <RealtyProvider>
                <Limitations />
              </RealtyProvider>
            </BnovoProvider>
          </ChildrenWrapper>
        </ArrowText>
      </DropdownWrapper>
      <DropdownWrapper>
        <ArrowText title={t('hotel_services_title')} size={textSize}>
          <ChildrenWrapper>
            <RealtyProvider>
              <HotelServices />
            </RealtyProvider>
          </ChildrenWrapper>
        </ArrowText>
      </DropdownWrapper>

      <DropdownWrapper>
        <ArrowText title={t('rate_table_title')} size={textSize}>
          <ChildrenWrapper>
            <RealtyProvider>
              <BnovoProvider>
                {!hasAnyIntegration && <RateTable />}
              </BnovoProvider>
            </RealtyProvider>
          </ChildrenWrapper>
        </ArrowText>
      </DropdownWrapper>
    </Wrapper>
  );
};

export default Content;
