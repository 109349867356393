import {
  Dispatch,
  FC,
  SetStateAction,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import styled from '@emotion/styled';
import useTranslation from 'next-translate/useTranslation';

import {HotelContext} from 'source/context/hotel';
import CLongPolling from 'source/utilities/long-polling';
import {HotelsRealtyIntegrationsCreateRequest} from 'types/api-scheme';
import {Text} from 'library/components/text';
import {useAppSelector} from 'source/store';
import {Option, Select} from 'library/components/select';

import {
  handleDeleteRealtyIntegrations,
  handleCreateRealtyIntegration,
  handleFetchCopyableRooms,
  handleRestoreRealtyIntegration,
  pollingRealtyIntegrations,
} from '../network';
import {buildCopyableRoomsOptions} from '../lib/utils';
import {RealtyAddNewRoomIntegrations} from './realty-add-new-room-integration';
import {selectBedTypes, selectFacilities, selectRoomCategories} from '../store';
import {RealtyIntegrationTable} from './realty-integration-table';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 40px;
  gap: 20px;
`;

const TableWrapper = styled.div`
  width: 100%;
  height: 100%;
  overflow: auto;
  padding: 0;
  margin-bottom: 40px;
  border-top: 1px solid ${({theme}) => theme.palette.border};
`;

const StyledSelect = styled(Select)`
  max-width: 440px;
`;

const HintWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const NoIntegratedRoomsText = styled(Text)`
  display: flex;
  justify-content: center;
  margin: 40px 0;
`;

interface IProps {
  loading: boolean;
  setLoading: Dispatch<boolean>;
  realtyIntegrations: RealtyIntegration[];
  setRealtyIntegrations: Dispatch<SetStateAction<RealtyIntegration[]>>;
}

export const RealtyIntegrationContent: FC<IProps> = ({
  loading,
  setLoading,
  realtyIntegrations,
  setRealtyIntegrations,
}) => {
  const {t} = useTranslation('integration');
  const [hotel] = useContext(HotelContext);
  const roomCategories = useAppSelector(selectRoomCategories);
  const bedTypes = useAppSelector(selectBedTypes);
  const facilities = useAppSelector(selectFacilities);
  const tableRef = useRef<HTMLDivElement>(null);
  const longPollingReference = useRef<CLongPolling | null>(null);

  const [selectedRoom, setSelectedRoom] = useState<Room | null>(null);

  const [initialCopyableRooms, setInitialCopyableRooms] =
    useState<CopyableIntegrationRooms>([]);
  const [copyableRooms, setCopyableRooms] = useState<CopyableIntegrationRooms>(
    [],
  );
  const copyableRoomsOptions = buildCopyableRoomsOptions(copyableRooms, t);
  const [copyableRoomOption, setCopyableRoomOption] = useState<Option>(
    copyableRoomsOptions[0],
  );

  const onCopyableRoomsComplete = (data?: CopyableIntegrationRooms) => {
    if (data) {
      setInitialCopyableRooms(data);
    }
  };

  const onCreateIntegrationComplete = (data: RealtyIntegration) => {
    setRealtyIntegrations((previous) => [...previous, data]);
    setCopyableRoomOption(copyableRoomsOptions[0]);
    setSelectedRoom(null);

    if (tableRef?.current) {
      tableRef.current.scrollIntoView({behavior: 'smooth', block: 'end'});
    }
  };

  const requestCopyableRooms = () => {
    if (hotel?.id) {
      handleFetchCopyableRooms(hotel.id, onCopyableRoomsComplete, setLoading);
    }
  };

  const onDeleteIntegrationComplete = (
    integrationId: RealtyIntegration['id'],
  ) => {
    setRealtyIntegrations((previous) =>
      previous.map((integration) => {
        if (integration.id === integrationId) {
          return {...integration, is_deleted: true};
        }

        return integration;
      }),
    );
    setCopyableRoomOption(copyableRoomsOptions[0]);
  };

  const onRestoreIntegrationComplete = (
    integrationId: RealtyIntegration['id'],
  ) => {
    setRealtyIntegrations((previous) =>
      previous.map((integration) => {
        if (integration.id === integrationId) {
          return {...integration, is_deleted: false};
        }

        return integration;
      }),
    );
  };

  const handleEnableIntegration = (
    data: HotelsRealtyIntegrationsCreateRequest,
  ) => {
    if (hotel?.id) {
      const payload = {
        ...data,
        room_id:
          selectedRoom && Number(selectedRoom.id) > 0
            ? selectedRoom.id
            : undefined,
      };

      handleCreateRealtyIntegration(
        hotel.id,
        payload,
        t,
        onCreateIntegrationComplete,
        setLoading,
      );
    }
  };

  const handleSwitchIntegrationStatus = (integration: RealtyIntegration) => {
    if (!hotel) {
      return;
    }

    if (integration.is_deleted) {
      handleRestoreRealtyIntegration(
        hotel.id,
        integration.id,
        t,
        onRestoreIntegrationComplete,
        setLoading,
      );

      return;
    }

    handleDeleteRealtyIntegrations(
      hotel.id,
      integration.id,
      t,
      onDeleteIntegrationComplete,
      setLoading,
    );
  };

  useEffect(() => {
    // Получение возможных для копирования комнат и включенных интеграций
    requestCopyableRooms();
  }, []);

  useEffect(() => {
    // активные интеграции
    setRealtyIntegrations(realtyIntegrations);
  }, [realtyIntegrations]);

  useEffect(() => {
    // Фильтрует комнаты для копирования - исключает уже включенные комнаты
    const filteredCopyableRooms = initialCopyableRooms.filter((room) => {
      if (realtyIntegrations.length === 0) {
        return true;
      }

      return !realtyIntegrations.some(
        (integration) => integration.copied_room_id === room.id,
      );
    });

    setCopyableRooms(filteredCopyableRooms);
  }, [realtyIntegrations, initialCopyableRooms]);

  useEffect(() => {
    // Исходя из выбранного селекта вставляет данные в форму
    if (copyableRoomOption.value < 1) {
      setSelectedRoom(null);
      return;
    }

    const currentRoom = copyableRooms.find(
      (copyableRoom) => copyableRoom.id === copyableRoomOption.value,
    );

    if (currentRoom) {
      setSelectedRoom(currentRoom);
    }
  }, [copyableRoomOption, copyableRooms]);

  useEffect(() => {
    // Каждые 5 секунд обновляет массив интеграций для обновления статусов
    const LongPolling = longPollingReference.current;

    if (hotel && !LongPolling && realtyIntegrations.length > 0) {
      longPollingReference.current = pollingRealtyIntegrations(
        hotel.id,
        (data) => {
          if (data) {
            setRealtyIntegrations(data);
          }
        },
      );
      longPollingReference.current?.start();
    }

    return () => {
      const LongPolling = longPollingReference.current;

      if (LongPolling) {
        LongPolling.stop();
        LongPolling.subscribe?.unsubscribe();
        longPollingReference.current = null;
      }
    };
  }, [hotel, realtyIntegrations]);

  return (
    <Wrapper>
      <Text size="boldM">{t('room.integrated_rooms')}</Text>
      <TableWrapper ref={tableRef}>
        <RealtyIntegrationTable
          realtyIntegrations={realtyIntegrations}
          handleSwitchIntegrationStatus={handleSwitchIntegrationStatus}
          loading={loading}
        />
        {realtyIntegrations.length === 0 && (
          <NoIntegratedRoomsText size="M">
            {t('room.no_integrated_rooms')}
          </NoIntegratedRoomsText>
        )}
      </TableWrapper>
      <Text size="boldM">{t('room.choose_room')}</Text>
      <HintWrapper>
        <Text size="S">{t('room.add_realty_integration_hint')}</Text>
        <Text size="S">{t('room.add_realty_integration_hint_2')}</Text>
      </HintWrapper>

      <StyledSelect
        value={copyableRoomOption}
        options={copyableRoomsOptions}
        setValue={setCopyableRoomOption}
        disabled={loading}
      />
      <RealtyAddNewRoomIntegrations
        roomCategories={roomCategories}
        realtyIntegrations={realtyIntegrations}
        bedTypes={bedTypes}
        facilities={facilities}
        room={selectedRoom}
        onSave={handleEnableIntegration}
        loading={loading}
      />
    </Wrapper>
  );
};
