import styled from '@emotion/styled';
import {Select} from 'library/components/select';
import {Switch} from 'library/components/switch';
import {Text} from 'library/components/text';
import {Textarea} from 'library/components/textarea';
import useTranslation from 'next-translate/useTranslation';
import {useEffect, useMemo, useState} from 'react';
import {
  buildPlacementDescription,
  getCheckInEndOption,
  getCheckInStartOption,
  getCheckOutEndOption,
  getCheckOutStartOption,
  isExistExitAroundClock,
  isPlacementDescriptionValueExist,
  parseCheckinEndTime,
  parseCheckoutEndTime,
} from 'slices/policies/lib/helpers';
import {
  clearPlacementDescription,
  selectPolicies,
  setConfirmationInvoice,
  setPlacementDescription,
  setPlacementDescriptions,
  setPlacementTime,
} from 'slices/policies/store';
import {CheckboxButton} from 'slices/rate/ui/checkbox-button';
import {useAppDispatch, useAppSelector} from 'source/store';
import {useDeviceDetection} from 'source/utilities/hooks/use-device-detection';
import {ELanguage} from 'source/utilities/language';
import {
  DEFAULT_DESCRIPTION_VALUE,
  END_TIME_OPTIONS,
  START_TIME_OPTIONS,
  initialPoliciesState,
} from '../../lib/mocks';

const InputsWrapper = styled.div<{isMobile: boolean}>`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  flex-grow: 1;
  max-width: ${({isMobile}) => (isMobile ? '100%' : '440px')};
`;

const InputLabel = styled(Text)`
  margin-bottom: 5px;
  font-weight: 500;
`;

const InputContainer = styled.div<{isMobile: boolean}>`
  max-width: 440px;
  margin: ${({isMobile}) =>
    isMobile ? '0px 0px 20px 0px' : '0px 20px 20px 0px'};
`;

const SwitchInOutAroundClock = styled.div`
  display: flex;
  justify-content: start;
  align-items: center;
`;

const SwitchLabel = styled(Text)`
  background: !important;
  margin-right: 18px;
`;

const TextareaIn = styled(Textarea)`
  font-weight: 300;
`;

const CheckboxButtonWrapper = styled.div`
  margin-bottom: 20px;
`;

const CheckInOut = () => {
  const dispatch = useAppDispatch();
  const {policies} = useAppSelector(selectPolicies);
  const {placement_time, confirmation_invoice} = policies;
  const {t} = useTranslation('policies');

  const isDescriptionValueExist = isPlacementDescriptionValueExist(
    placement_time?.description,
  );

  const description = buildPlacementDescription(placement_time?.description);
  const isAroundClock = !isExistExitAroundClock(
    placement_time?.checkin_end,
    placement_time?.checkout_start,
  );

  const [selectLanguage, setSelectLanguage] = useState<
    (typeof ELanguage)[keyof typeof ELanguage]
  >(ELanguage.RU);
  const mobile = useDeviceDetection('mobile');

  const [openDescription, setOpenDescription] = useState<boolean>(
    isDescriptionValueExist,
  );

  const chekinEndOptions = useMemo(
    () => parseCheckinEndTime(END_TIME_OPTIONS, placement_time?.checkin_start),
    [placement_time?.checkin_start],
  );

  const checkoutEndOptions = useMemo(
    () =>
      parseCheckoutEndTime(
        END_TIME_OPTIONS,
        placement_time?.checkout_start,
        isAroundClock,
      ),
    [isAroundClock, placement_time?.checkout_start],
  );

  const handlePlacementForAroundClock = (enabled: boolean) => {
    const checkIn = enabled
      ? ''
      : getCheckInEndOption(placement_time?.checkin_end).value;
    const checkOutStart = enabled
      ? ''
      : getCheckOutStartOption(placement_time?.checkout_start).value;
    dispatch(
      setPlacementTime({
        key: 'checkin_end',
        value: checkIn,
      }),
    );
    dispatch(
      setPlacementTime({
        key: 'checkout_start',
        value: checkOutStart,
      }),
    );
  };

  const onDescriptionSwitch = async (enabled: boolean) => {
    const initialPlacementDescription =
      initialPoliciesState.placement_time?.description;

    setOpenDescription(enabled);
    if (!enabled) {
      dispatch(clearPlacementDescription());
    }
    if (
      enabled &&
      placement_time?.description?.length === 0 &&
      initialPlacementDescription
    ) {
      dispatch(setPlacementDescriptions(initialPlacementDescription));
    }
  };

  useEffect(() => {
    if (isAroundClock) {
      return;
    }

    // При изменении чекина С и изменении доступных опций в чекине ДО - выбирает первый возможный вариант из опций чекина ДО, если такого нет в списке опций
    const hasOptionsCheckoutEndOption = chekinEndOptions.some(
      ({value}) => value === placement_time?.checkin_end,
    );

    if (hasOptionsCheckoutEndOption) {
      return;
    }

    dispatch(
      setPlacementTime({key: 'checkin_end', value: chekinEndOptions[0].value}),
    );
  }, [chekinEndOptions, isAroundClock]);

  useEffect(() => {
    if (isAroundClock) {
      return;
    }

    // При изменении чекаута С и изменении доступных опций в чекауте ДО - выбирает первый возможный вариант из опций чекаута ДО, если такого нет в списке опций
    // При круглосуточном заезде/выезда ничего не делает, заезд С и выезд ДО независимы друг от друга
    const hasOptionsCheckoutEndOption = checkoutEndOptions.some(
      ({value}) => value === placement_time?.checkout_end,
    );

    if (hasOptionsCheckoutEndOption) {
      return;
    }

    dispatch(
      setPlacementTime({
        key: 'checkout_end',
        value: checkoutEndOptions[0].value,
      }),
    );
  }, [checkoutEndOptions, isAroundClock]);

  return (
    <div>
      <InputContainer isMobile={mobile}>
        <SwitchInOutAroundClock>
          <SwitchLabel size="S">
            {t('check-in-out.checkbox-around-clock')}
          </SwitchLabel>
          <Switch
            initialState={isAroundClock}
            isActive={isAroundClock}
            onSwitch={async (enabled) => {
              handlePlacementForAroundClock(enabled);
            }}
          />
        </SwitchInOutAroundClock>
      </InputContainer>
      <InputsWrapper isMobile={mobile}>
        <InputContainer isMobile={mobile}>
          <InputLabel size="S">{t('check-in-out.input-in-start')}</InputLabel>
          <Select
            value={getCheckInStartOption(placement_time?.checkin_start)}
            options={START_TIME_OPTIONS}
            setValue={(option) =>
              dispatch(
                setPlacementTime({key: 'checkin_start', value: option.value}),
              )
            }
          />
        </InputContainer>
        {!isAroundClock && (
          <>
            <InputContainer isMobile={mobile}>
              <InputLabel size="S">{t('check-in-out.input-in-end')}</InputLabel>
              <Select
                value={getCheckInEndOption(placement_time?.checkin_end)}
                options={chekinEndOptions}
                setValue={(option) =>
                  dispatch(
                    setPlacementTime({key: 'checkin_end', value: option.value}),
                  )
                }
              />
            </InputContainer>
            <InputContainer isMobile={mobile}>
              <InputLabel size="S">
                {t('check-in-out.input-out-start')}
              </InputLabel>
              <Select
                value={getCheckOutStartOption(placement_time?.checkout_start)}
                options={START_TIME_OPTIONS}
                setValue={(option) =>
                  dispatch(
                    setPlacementTime({
                      key: 'checkout_start',
                      value: option.value,
                    }),
                  )
                }
              />
            </InputContainer>
          </>
        )}
        <InputContainer isMobile={mobile}>
          <InputLabel size="S">{t('check-in-out.input-out-end')}</InputLabel>
          <Select
            value={getCheckOutEndOption(placement_time?.checkout_end)}
            options={checkoutEndOptions}
            setValue={(option) =>
              dispatch(
                setPlacementTime({key: 'checkout_end', value: option.value}),
              )
            }
          />
        </InputContainer>
      </InputsWrapper>
      <InputContainer isMobile={mobile}>
        <SwitchInOutAroundClock>
          <SwitchLabel size="S">
            {t('check-in-out.documents_provision')}
          </SwitchLabel>
          <Switch
            initialState={confirmation_invoice}
            isActive={confirmation_invoice}
            onSwitch={async (enabled) => {
              dispatch(setConfirmationInvoice(enabled));
            }}
          />
        </SwitchInOutAroundClock>
      </InputContainer>
      <InputContainer isMobile={mobile}>
        <SwitchInOutAroundClock>
          <SwitchLabel size="S">
            {t('check-in-out.feature-settlement-checkbox')}
          </SwitchLabel>
          <Switch
            initialState={openDescription}
            isActive={openDescription}
            onSwitch={onDescriptionSwitch}
          />
        </SwitchInOutAroundClock>
      </InputContainer>
      {openDescription && (
        <>
          <InputContainer isMobile={mobile}>
            <CheckboxButtonWrapper>
              <CheckboxButton
                isSelect={selectLanguage === ELanguage.RU}
                onClick={() => setSelectLanguage(ELanguage.RU)}
              >
                {t('check-in-out.placement-language.ru')}
              </CheckboxButton>
              <CheckboxButton
                isSelect={selectLanguage === ELanguage.EN}
                onClick={() => setSelectLanguage(ELanguage.EN)}
              >
                {t('check-in-out.placement-language.en')}
              </CheckboxButton>
            </CheckboxButtonWrapper>
          </InputContainer>

          {description?.map((description) => {
            if (description.locale === selectLanguage) {
              return (
                <InputsWrapper
                  key={`time-${description.locale}`}
                  isMobile={mobile}
                >
                  <TextareaIn
                    value={
                      description.value === DEFAULT_DESCRIPTION_VALUE
                        ? ''
                        : description.value ?? ''
                    }
                    onChange={({target: {value}}) => {
                      dispatch(
                        setPlacementDescription({
                          value,
                          locale: description.locale,
                        }),
                      );
                    }}
                    onClear={() =>
                      dispatch(
                        setPlacementDescription({
                          value: '',
                          locale: description.locale,
                        }),
                      )
                    }
                    placeholder={t(
                      'check-in-out.feature-settlement-placeholder',
                    )}
                  />
                </InputsWrapper>
              );
            }
          })}
        </>
      )}
    </div>
  );
};

export default CheckInOut;
