import {useRouter} from 'next/router';
import {useContext} from 'react';

import {handleClick} from 'slices/user/lib/helpers';
import {UserContext} from 'source/context/user';
import {AuthorizationContext} from 'source/context/authorization';
import {useSessionStore} from 'source/context/session-store';
import {HeaderButton} from 'source/components/header/ui';
import useTranslation from 'next-translate/useTranslation';

export const Logout = () => {
  const {t} = useTranslation('common');
  const router = useRouter();
  const [, setSessionStore] = useSessionStore();
  const [, setUser] = useContext(UserContext);
  const [, setAuthorization] = useContext(AuthorizationContext);

  return (
    <HeaderButton
      iconName="logout"
      highlightText
      onClick={handleClick(router, setUser, setAuthorization, setSessionStore)}
    >
      {t('logout')}
    </HeaderButton>
  );
};
