import {useTheme} from '@emotion/react';
import styled from '@emotion/styled';
import {ActionModal} from 'library/components/action-modal';
import {Headline} from 'library/components/headline';
import {IconButton} from 'library/components/icon-button';
import {CircularLoader} from 'library/components/loader';
import {Text} from 'library/components/text';
import {Tooltip} from 'library/components/tooltip';
import {PencilIcon} from 'library/icons/pencil-icon';

import useTranslation from 'next-translate/useTranslation';
import Image from 'next/image';
import {useRouter} from 'next/router';
import {FC, MouseEvent, MouseEventHandler, useContext, useState} from 'react';
import {AddImagesCard} from 'slices/manage/ui/add-images-card';
import {deleteRoom} from 'slices/rooms/network';
import {selectRooms, setRooms} from 'slices/rooms/store';
import {Icon} from 'source/components/icon';
import {HotelContext} from 'source/context/hotel';
import {useAppDispatch, useAppSelector} from 'source/store';
import {useRealtyIntegration} from 'source/utilities/hooks/use-is-realty-integration';

const Wrapper = styled.div`
  border-radius: 6px;
  cursor: pointer;
  transition: box-shadow 200ms ease-in-out;
  box-shadow: 0 4px 20px ${({theme}) => theme.palette.cardShadowColor};

  &:hover {
    box-shadow: 0 0 0 2px ${({theme}) => theme.palette.fontPrimary};
    .delete-hotel-icon {
      opacity: 1;
    }
  }
`;

const RoomTitle = styled(Headline)`
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
`;

const HeaderWrapper = styled.div`
  height: 69.6px;
`;

const FooterWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: end;
`;

const InfoTitle = styled(Text)`
  margin-bottom: 7px;
`;

const Id = styled(Text)`
  color: ${({theme}) => theme.palette.borderBlue};
  font-weight: 600;
`;

const ImageWrapper = styled.div`
  height: 210px;
  position: relative;
  border-radius: 6px 6px 0 0;

  img {
    border-radius: 6px 6px 0 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

const ContentWrapper = styled.div`
  padding: 18px;
`;

const ActionsWrapper = styled.div`
  margin-top: 20px;
  display: flex;
  & > div {
    margin-right: 16px;
  }
  &:last-child {
    margin-right: 0;
  }
`;

const NoImageWrapper = styled.div`
  border-radius: 4.75px 4.75px 0 0;
  padding: 20px;
  border: 2px solid ${({theme}) => theme.palette.border};
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  background-color: ${({theme}) => theme.palette.darkerBackground};
`;

const Action = styled(IconButton)`
  padding: 8px;
  border: 1px solid ${({theme}) => theme.palette.border};
  border-radius: 6px;
  cursor: pointer;
  &:hover {
    border: 1px solid ${({theme}) => theme.palette.primaryAccent};
    transition: 300ms;
  }
`;

const Pencil = styled(PencilIcon)`
  width: 21px;
  height: 21px;
`;

const LoaderWrapper = styled.div`
  width: 100%;
  height: 100%;
  background: ${({theme}) => theme.palette.fontAccent};
  display: flex;
  align-items: center;
  justify-content: center;
  border-top-right-radius: 6px;
  border-top-left-radius: 6px;
`;
interface RoomCardProperties {
  room: RoomsList[number];
}

export const RoomCard: FC<RoomCardProperties> = ({room}) => {
  const [loading, setLoading] = useState(false);
  const [hotel] = useContext(HotelContext);
  const [hasRealtyIntegration] = useRealtyIntegration();
  const theme = useTheme();
  const router = useRouter();
  const dispatch = useAppDispatch();
  const rooms = useAppSelector(selectRooms);
  const {t} = useTranslation();

  const removeRoom = async (
    event: MouseEvent<HTMLButtonElement>,
    close?: MouseEventHandler<HTMLButtonElement>,
  ) => {
    setLoading(true);
    if (hotel?.id && room.id) {
      deleteRoom(hotel.id, room.id, () => {
        dispatch(setRooms(rooms.filter(({id}) => id !== room.id)));
      }).finally(() => setLoading(false));
    }

    if (close) {
      close(event);
    }
  };
  return (
    <Wrapper
      onClick={() => {
        router.push(`/manage/${hotel?.id}/rooms/${room.id}/edit`);
      }}
    >
      {loading ? (
        <ImageWrapper>
          <LoaderWrapper>
            <CircularLoader size={64} />
          </LoaderWrapper>
        </ImageWrapper>
      ) : (
        <ImageWrapper>
          {room.main_image ? (
            <Image
              src={room.main_image}
              alt={room.name}
              width={325}
              height={210}
            />
          ) : hotel ? (
            <AddImagesCard
              href={`/manage/${hotel.id}/rooms/${room.id}/photos`}
            />
          ) : (
            <NoImageWrapper>
              <Icon
                style={{borderRadius: '6px 6px 0 0'}}
                name="no-image"
                width="100%"
                height="100%"
              />
            </NoImageWrapper>
          )}
        </ImageWrapper>
      )}
      <ContentWrapper>
        <HeaderWrapper>
          <RoomTitle level="M">{room.name}</RoomTitle>
          <Id size="S">{t('rooms:id', {id: room.id})}</Id>
        </HeaderWrapper>

        <FooterWrapper>
          <InfoTitle size="S">
            {t('rooms:info_title', {guests: room.max_guests_number})}
          </InfoTitle>
          {/* TODO: uncomment when backend ready for allotment count */}
          {/* <Info size="S">{t('rooms:adults', {adults: room.allotment.adults})}</Info>
          <Info size="S">{t('rooms:kids', {kids: room.allotment.kids})}</Info> */}
        </FooterWrapper>

        <ActionsWrapper>
          <Tooltip
            on="hover"
            position="bottom left"
            trigger={
              <div>
                <Action
                  onClick={() => {
                    router.push(`/manage/${hotel?.id}/rooms/${room.id}/edit`);
                  }}
                >
                  <Pencil color={theme.palette.primaryAccent} />
                </Action>
              </div>
            }
          >
            {t('rooms:actions.edit_room')}
          </Tooltip>
          <Tooltip
            on="hover"
            position="bottom left"
            trigger={
              <div>
                <Action
                  onClick={(event) => {
                    event.stopPropagation();
                    router.push(`/manage/${hotel?.id}/rooms/${room.id}/photos`);
                  }}
                >
                  <Icon name="photo" width={21} height={21} />
                </Action>
              </div>
            }
          >
            {t('rooms:actions.edit_photos')}
          </Tooltip>
          {!hasRealtyIntegration && (
            <Tooltip
              on="hover"
              position="bottom left"
              trigger={
                <div>
                  <Action>
                    <ActionModal
                      maxWidth={440}
                      title={t('rooms:delete_room', {room: room.name})}
                      action={(close, event) => removeRoom(event, close)}
                      actionText={t('components:yes')}
                    >
                      <Icon name="trash-bin" width={21} height={21} />
                    </ActionModal>
                  </Action>
                </div>
              }
            >
              {t('rooms:actions.remove_room')}
            </Tooltip>
          )}
        </ActionsWrapper>
      </ContentWrapper>
    </Wrapper>
  );
};
