import styled from '@emotion/styled';
import {useContext, useEffect, useState} from 'react';
import useTranslation from 'next-translate/useTranslation';

import {HotelContext} from 'source/context/hotel';
import {theme} from 'source/utilities/global-style';
import {Button} from 'library/components/button';
import {LabeledComponent} from 'library/components/labeled-component';
import {CircularLoader} from 'library/components/loader';
import {Option, Select} from 'library/components/select';

import {CHANNEL_MANAGERS_OPTIONS} from 'slices/integration/lib/constants';
import {EIntegrations} from 'slices/integration/lib/types';
import {
  handleEnableIntegration,
  handleFetchRealtyIntegrations,
} from 'slices/integration/network';

import {useAppSelector} from 'source/store';
import {NoContent} from 'library/components/no-content';
import {isEmpty} from 'ramda';

import {BnovoIntegrationContent} from './ui/bnovo-integration-content';
import {NeedConfigureBadge} from './ui/need-configure-badge';
import {getIntegrationOptionByValue} from './lib/utils';
import {selectRates, selectRoomShorts} from './store';
import {RealtyIntegrationContent} from './ui/realty-integration-content';

const Wrapper = styled.div`
  margin-top: 40px;
`;

const ChoiceChannelManagerWrapper = styled.div``;

const StyledButton = styled(Button)`
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  margin-top: 20px;
  padding: 15px;
  width: 200px;
`;

const Label = styled(LabeledComponent)`
  margin-bottom: 26px;
`;

const StyledSelect = styled(Select)`
  max-width: 440px;
`;

const Content = () => {
  const {t} = useTranslation('integration');
  const [hotel, setHotel] = useContext(HotelContext);

  const bnovoIntegration = hotel?.bnovo_integration;

  const [currentIntegration, setCurrentIntegration] =
    useState<EIntegrations | null>(null);

  const roomShorts = useAppSelector(selectRoomShorts);
  const rates = useAppSelector(selectRates);

  const [realtyIntegrations, setRealtyIntegrations] = useState<
    RealtyIntegration[]
  >([]);
  const hasAnyEnabledRealtyIntegrations = realtyIntegrations.some(
    (integration) => !integration.is_deleted,
  );
  const [bnovoToken, setBnovoToken] = useState(bnovoIntegration?.token || '');
  const [selectIntegrationType, setSelectIntegrationType] = useState<
    Option<EIntegrations>
  >(CHANNEL_MANAGERS_OPTIONS[0]);
  const [loading, setLoading] = useState(false);

  const isBnovoSelected = selectIntegrationType.value === EIntegrations.BNOVO;

  const isNoRoomsOnBnovo = isBnovoSelected && isEmpty(roomShorts);

  const isNoRatesOnBnovo = isBnovoSelected && isEmpty(rates);

  const isRealtyIntegrationProcess =
    currentIntegration === EIntegrations.REALTY &&
    !hasAnyEnabledRealtyIntegrations;

  const hasNoIntegrations =
    !bnovoToken &&
    !bnovoIntegration?.is_configured &&
    !hasAnyEnabledRealtyIntegrations;

  const onEnableIntegration = () => {
    if (!hotel?.id) {
      return;
    }

    if (isBnovoSelected) {
      setCurrentIntegration(EIntegrations.BNOVO);
      handleEnableIntegration(
        hotel.id,
        selectIntegrationType,
        t,
        setLoading,
        setHotel,
      );
    }

    if (selectIntegrationType.value === EIntegrations.REALTY) {
      setCurrentIntegration(EIntegrations.REALTY);
    }
  };

  useEffect(() => {
    if (hasAnyEnabledRealtyIntegrations) {
      const realtyOption = getIntegrationOptionByValue(
        CHANNEL_MANAGERS_OPTIONS,
        EIntegrations.REALTY,
      );

      setSelectIntegrationType(realtyOption);
      setCurrentIntegration(EIntegrations.REALTY);

      return;
    }

    if (bnovoIntegration) {
      const bnovoOption = getIntegrationOptionByValue(
        CHANNEL_MANAGERS_OPTIONS,
        EIntegrations.BNOVO,
      );

      setCurrentIntegration(EIntegrations.BNOVO);
      setSelectIntegrationType(bnovoOption);
    }
  }, [bnovoIntegration, hasAnyEnabledRealtyIntegrations]);

  useEffect(() => {
    setBnovoToken(bnovoIntegration?.token ?? '');
  }, [bnovoIntegration]);

  useEffect(() => {
    setHotel((previous) => {
      if (previous) {
        return {...previous, realty_integrations: realtyIntegrations};
      }

      return previous;
    });
  }, [realtyIntegrations]);

  useEffect(() => {
    if (hotel?.id) {
      handleFetchRealtyIntegrations(
        hotel.id,
        (data) => {
          if (data) {
            setRealtyIntegrations(data);
          }
        },
        setLoading,
      );
    }
  }, [hotel?.id]);

  return (
    <Wrapper>
      {bnovoIntegration && !bnovoIntegration.is_configured && bnovoToken && (
        <NeedConfigureBadge selectIntegrationType={selectIntegrationType} />
      )}
      <ChoiceChannelManagerWrapper>
        <Label
          gap={11}
          size="S"
          color={theme.palette.fontSecondary}
          text={t('choice_channel_manager.title')}
        >
          <StyledSelect
            value={selectIntegrationType}
            options={CHANNEL_MANAGERS_OPTIONS}
            setValue={setSelectIntegrationType}
            disabled={
              !hasNoIntegrations || loading || isRealtyIntegrationProcess
            }
          />
          {hasNoIntegrations && !isRealtyIntegrationProcess && (
            <StyledButton
              onClick={onEnableIntegration}
              disabled={isNoRoomsOnBnovo || isNoRatesOnBnovo}
            >
              {loading ? (
                <CircularLoader variant="thin" size={20} />
              ) : (
                t('choice_channel_manager.start_button_label')
              )}
            </StyledButton>
          )}
          {isNoRoomsOnBnovo && (
            <div>
              <NoContent
                subtitle={t('no_rooms')}
                buttonLabel={t('add_room')}
                href={`/manage/${hotel?.id}/rooms/add`}
              />
            </div>
          )}
          {!isNoRoomsOnBnovo && isNoRatesOnBnovo && (
            <div>
              <NoContent
                subtitle={t('no_rates')}
                buttonLabel={t('add_rate')}
                href={`/manage/${hotel?.id}/rates`}
              />
            </div>
          )}
          {isRealtyIntegrationProcess && (
            <StyledButton onClick={() => setCurrentIntegration(null)}>
              {loading ? (
                <CircularLoader variant="thin" size={20} />
              ) : (
                t('back')
              )}
            </StyledButton>
          )}
          {currentIntegration === EIntegrations.BNOVO && (
            <BnovoIntegrationContent
              token={bnovoToken}
              loading={loading}
              setLoading={setLoading}
            />
          )}
          {currentIntegration === EIntegrations.REALTY && (
            <RealtyIntegrationContent
              loading={loading}
              setLoading={setLoading}
              realtyIntegrations={realtyIntegrations}
              setRealtyIntegrations={setRealtyIntegrations}
            />
          )}
        </Label>
      </ChoiceChannelManagerWrapper>
    </Wrapper>
  );
};

export default Content;
