import styled from '@emotion/styled';
import {Button} from 'library/components/button';
import {Input} from 'library/components/input';
import useTranslation from 'next-translate/useTranslation';
import {Dispatch, FC, SetStateAction} from 'react';
import {selectLoading} from 'slices/balance/store';
import {useAppSelector} from 'source/store';

const InputWrapper = styled.div`
  position: relative;
`;

const WithdrawAll = styled(Button)`
  padding: 0;
  margin-top: 5px;
  border: none;
  color: ${({theme}) => theme.palette.fontPrimary};

  &:hover {
    opacity: 0.7;
  }
`;

interface ISumInputProps {
  maxCount: InputProperties['maxCount'];
  amount: string;
  setAmount: Dispatch<SetStateAction<string>>;
}

export const SumInput: FC<ISumInputProps> = ({maxCount, amount, setAmount}) => {
  const {t} = useTranslation('balance');
  const loading = useAppSelector(selectLoading);

  const handleWithdrawAll = () => {
    if (maxCount) {
      setAmount(String(maxCount));
    }
  };

  return (
    <InputWrapper>
      <Input
        placeholder={t('balance_withdrawal.modal.main.inputs.sum.placeholder')}
        type="number"
        disabled={loading}
        value={amount}
        onChange={({target}) => setAmount(target.value)}
        maxCount={maxCount}
      />
      {maxCount && (
        <WithdrawAll
          buttonType="secondary"
          textSize="XS"
          onClick={handleWithdrawAll}
        >
          {t('balance_withdrawal.modal.main.inputs.sum.withdrawal_all')}
        </WithdrawAll>
      )}
    </InputWrapper>
  );
};
