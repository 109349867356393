import type {GetServerSideProps} from 'next';

import {andThen, assoc, call, pipeWith} from 'ramda';
import {api} from 'source/utilities/api';
import {checkUser} from 'source/utilities/api/user-authorization';
import {handleException} from 'source/utilities/exceptions/business';

import {handleApiException} from 'source/utilities/exceptions/network';

import {isMobile, isTablet} from 'source/utilities/guards/device-detection';
import {serverHeaders} from 'source/utilities/network/http';

import {redirects} from 'source/utilities/network/url';
import {removeUndefined} from 'source/utilities/object';
import {checkIdValidity, transformToNumber} from 'source/utilities/parameter';
import {ApplicationProperties} from 'source/utilities/ui';
import {PhotosPageProperties} from './lib/types';

export const getPhotosPageProperties: GetServerSideProps<
  PhotosPageProperties | ServerSideRedirect
> = (context) => {
  const headers = serverHeaders(context.req.headers, context.req.cookies);
  const hotelId = transformToNumber(context?.params?.hotel_slug);

  const userAgent = context.req.headers['user-agent'];

  const utilities = {
    mobile: isMobile(userAgent),
    tablet: isTablet(userAgent),
    windowWidth: 0,
  };

  const requestHotel = () =>
    api.hotel.get({
      hotelId,
      headers,
    });

  const requestImages = () =>
    api.hotelImages.get({
      hotelId,
      headers,
    });

  return call(
    pipeWith(andThen, [
      () => checkUser(utilities, headers),
      async (properties: ApplicationProperties) => {
        try {
          checkIdValidity(hotelId);

          const [hotel, images] = await Promise.all([
            requestHotel(),
            requestImages() as Promise<HotelImages>,
          ]);
          const result = removeUndefined({
            ...properties,
            hotel,
            images,
          });

          return assoc('props', result, {});
        } catch (error) {
          console.error(error);
          return {redirect: redirects.manage};
        }
      },
    ]),
  ).catch(handleException);
};

export const deleteImages = (
  selectedImages: number[],
  onComplete: () => void,
  setLoading: LoadingDispatch,
  hotel?: Hotel | null,
) => {
  if (!hotel) {
    return;
  }
  const options = {hotelId: hotel.id, payload: {image_ids: selectedImages}};

  setLoading(true);
  api.hotelImages
    .deleteMany(options)
    .then(onComplete)
    .catch(handleException)
    .finally(() => setLoading(false));
};

export const deleteImage = (
  hotel: Hotel,
  imageId: number,
  onComplete: () => void,
  ...dispatchers: [LoadingDispatch]
) => {
  const options = {hotelId: hotel.id, imageId};
  const [setLoading] = dispatchers;

  setLoading(true);

  api.hotelImages
    .delete(options)
    .then(onComplete)
    .catch(handleException)
    .finally(() => setLoading(false));
};

export const reorderImages = async (
  hotel: Hotel,
  draggedImageId: number,
  preHotelImageId: number,
) => {
  const options = {
    hotelId: hotel.id,
    draggedImageId,
    payload: {preHotelImageId},
  };

  api.hotelImages.reorder(options).catch((error) => {
    handleApiException(error);
  });
};

export const changeHotelMainImage = (
  hotel: Hotel,
  mainImageId: number,
  onComplete: () => void,
  ...dispatchers: [HotelDispatch]
) => {
  const [setHotel] = dispatchers;

  api.hotel
    .update({hotelId: hotel.id, payload: {main_image_id: mainImageId}})
    .then(setHotel)
    .then(onComplete)
    .catch(handleException);
};
