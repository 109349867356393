import {useSortable} from '@dnd-kit/sortable';
import {CSS} from '@dnd-kit/utilities';
import {FC, useContext, useEffect, useMemo, useState} from 'react';
import {HotelContext} from 'source/context/hotel';
import {Dispatcher} from 'types/utilities';
import {useSelector} from 'react-redux';
import {selectRooms} from 'slices/rooms/store';
import {ImageCard} from './image-card';

interface Props {
  id: number;
  roomId?: number;
  isMainImage: (imageId: number, roomId?: number) => boolean;
  image: Image;
  isSelected: boolean;
  onImageSelect: () => void;
  onMainImageChange: (imageId: number, url: string) => Promise<void>;
  onDeleteComplete: (imageId: number) => void;
  onDeleteImage: (
    imageId: number,
    onComplete: () => void,
    setLoading: Dispatcher<boolean>
  ) => void;
}

export const SortableImageDesktop: FC<Props> = ({
  id,
  roomId,
  isMainImage,
  image,
  isSelected,
  onImageSelect,
  onMainImageChange,
  onDeleteComplete,
  onDeleteImage,
}) => {
  const {attributes, listeners, setNodeRef, transform, transition} =
    useSortable({id});

  const [hotel] = useContext(HotelContext);
  const [loading, setLoading] = useState(false);
  const rooms = useSelector(selectRooms);
  const [isMain, setIsMain] = useState(false);

  useEffect(() => {
    setIsMain(isMainImage(id, roomId));
  }, [rooms, hotel?.main_image?.id]);

  const setMainImage = () => {
    if (image.id) {
      setLoading(true);
      onMainImageChange(image.id, image.url).finally(() => setLoading(false));
    }
  };

  const onDelete = (imageId: number) => {
    const onComplete = () => {
      onDeleteComplete(imageId);
    };
    if (hotel?.id) {
      onDeleteImage(image.id, onComplete, setLoading);
    }
  };

  return (
    <ImageCard
      innerRef={setNodeRef}
      image={image}
      style={{
        transform: CSS.Transform.toString(transform),
        transition,
      }}
      loading={loading}
      isSelected={isSelected}
      isMainImage={isMain}
      setMainImage={setMainImage}
      onDeleteImage={onDelete}
      onImageSelect={onImageSelect}
      {...attributes}
      {...listeners}
    />
  );
};
