import type {NextRouter} from 'next/router';

import {MouseEvent} from 'react';
import {
  initialSessionStore,
  SessionStoreDispatch,
} from 'source/context/session-store';
import {CookieNames, deleteCookie} from 'source/utilities/network/cookies';

export const handleClick =
  (
    router: NextRouter,
    ...handlers: [UserDispatch, AuthorizationDispatch, SessionStoreDispatch]
  ) =>
  (event: MouseEvent<HTMLButtonElement>) => {
    const [setUser, setAuthorization, setSessionStore] = handlers;

    setUser(null);
    setAuthorization(false);
    deleteCookie(CookieNames.HOTELIER_AUTHORIZATION);
    deleteCookie(CookieNames.TEMPORARY_AUTHORIZATION);
    setSessionStore(() => initialSessionStore);
    router.push('/');
  };
