import dynamic from 'next/dynamic';
import {ComponentProps, FC, forwardRef} from 'react';

const icons = {
  search: dynamic({
    loader: () => import('./assets/search.svg'),
    ssr: false,
  }),
  'sort-desc': dynamic({
    loader: () => import('./assets/sort-desc.svg'),
    ssr: false,
  }),
  'sort-asc': dynamic({
    loader: () => import('./assets/sort-asc.svg'),
    ssr: false,
  }),
  guard: dynamic({
    loader: () => import('./assets/guard.svg'),
    ssr: false,
  }),
  'no-image': dynamic({
    loader: () => import('./assets/no-image.svg'),
    ssr: false,
  }),
  calendar: dynamic({
    loader: () => import('./assets/calendar.svg'),
    ssr: false,
  }),
  'trash-bin': dynamic({
    loader: () => import('./assets/trash-bin.svg'),
    ssr: false,
  }),
  photo: dynamic({
    loader: () => import('./assets/photo.svg'),
    ssr: false,
  }),
  'input-cross': dynamic({
    loader: () => import('./assets/input-cross.svg'),
    ssr: false,
  }),
  'logo-icon': dynamic({
    loader: () => import('./assets/logo-icon.svg'),
    ssr: false,
  }),
  'green-tick': dynamic({
    loader: () => import('./assets/green-tick.svg'),
    ssr: false,
  }),
  tick: dynamic({
    loader: () => import('./assets/tick.svg'),
    ssr: false,
  }),
  'logo-title': dynamic({
    loader: () => import('./assets/logo-title.svg'),
    ssr: false,
  }),
  'logo-subtitle': dynamic({
    loader: () => import('./assets/logo-subtitle.svg'),
    ssr: false,
  }),
  'hotel-search-placemark': dynamic({
    loader: () => import('./assets/hotel-search-placemark.svg'),
    ssr: false,
  }),
  'ostrovok-logo': dynamic({
    loader: () => import('./assets/ostrovok-logo.svg'),
    ssr: false,
  }),
  'booking-logo': dynamic({
    loader: () => import('./assets/booking-logo.svg'),
    ssr: false,
  }),
  'sutochno-logo': dynamic({
    loader: () => import('./assets/sutochno-logo.svg'),
    ssr: false,
  }),
  'user-icon': dynamic({
    loader: () => import('./assets/user-icon.svg'),
    ssr: false,
  }),
  'burger-menu': dynamic({
    loader: () => import('./assets/burger-menu.svg'),
    ssr: false,
  }),
  logout: dynamic({
    loader: () => import('./assets/logout.svg'),
    ssr: false,
  }),
  cross: dynamic({
    loader: () => import('./assets/cross.svg'),
    ssr: false,
  }),
  'home-page': dynamic({
    loader: () => import('./assets/home-page.svg'),
    ssr: false,
  }),
  'rates-page': dynamic({
    loader: () => import('./assets/rates-page.svg'),
    ssr: false,
  }),
  'hotels-page': dynamic({
    loader: () => import('./assets/hotels-page.svg'),
    ssr: false,
  }),
  'messages-page': dynamic({
    loader: () => import('./assets/messages-page.svg'),
    ssr: false,
  }),
  'reviews-page': dynamic({
    loader: () => import('./assets/reviews-page.svg'),
    ssr: false,
  }),
  'finance-page': dynamic({
    loader: () => import('./assets/finance-page.svg'),
    ssr: false,
  }),
  pencil: dynamic({
    loader: () => import('./assets/pencil.svg'),
    ssr: false,
  }),
  'pencil-white': dynamic({
    loader: () => import('./assets/pencil-white.svg'),
    ssr: false,
  }),
  'home-calendar': dynamic({
    loader: () => import('./assets/home-calendar.svg'),
    ssr: false,
  }),
  'home-mobile': dynamic({
    loader: () => import('./assets/home-mobile.svg'),
    ssr: false,
  }),
  'home-marker': dynamic({
    loader: () => import('./assets/home-marker.svg'),
    ssr: false,
  }),
  'home-dashed-airplane': dynamic({
    loader: () => import('./assets/home-dashed-airplane.svg'),
    ssr: false,
  }),
  'home-graphics': dynamic({
    loader: () => import('./assets/home-graphics.svg'),
    ssr: false,
  }),
  'home-settings': dynamic({
    loader: () => import('./assets/home-settings.svg'),
    ssr: false,
  }),
  'home-support': dynamic({
    loader: () => import('./assets/home-support.svg'),
    ssr: false,
  }),
  'sales-grow': dynamic({
    loader: () => import('./assets/sales-grow.svg'),
    ssr: false,
  }),
  'payment-method': dynamic({
    loader: () => import('./assets/payment-method.svg'),
    ssr: false,
  }),
  pro: dynamic({
    loader: () => import('./assets/pro.svg'),
    ssr: false,
  }),
  'star-top': dynamic({
    loader: () => import('./assets/star-top.svg'),
    ssr: false,
  }),
  speaker: dynamic({
    loader: () => import('./assets/speaker.svg'),
    ssr: false,
  }),
  gift: dynamic({
    loader: () => import('./assets/gift.svg'),
    ssr: false,
  }),
  banknote: dynamic({
    loader: () => import('./assets/banknote.svg'),
    ssr: false,
  }),
  integration: dynamic({
    loader: () => import('./assets/integration.svg'),
    ssr: false,
  }),
  'star-yellow': dynamic({
    loader: () => import('./assets/star-yellow.svg'),
    ssr: false,
  }),
  restore: dynamic({
    loader: () => import('./assets/restore.svg'),
    ssr: false,
  }),
  'verified-mark': dynamic({
    loader: () => import('./assets/verified-mark.svg'),
    ssr: false,
  }),
  'home-line': dynamic({
    loader: () => import('./assets/home-line.svg'),
    ssr: false,
  }),
  'home-airplane': dynamic({
    loader: () => import('./assets/home-airplane.svg'),
    ssr: false,
  }),
  'home-question': dynamic({
    loader: () => import('./assets/home-question.svg'),
    ssr: false,
  }),
  arrow: dynamic({
    loader: () => import('./assets/arrow.svg'),
    ssr: false,
  }),
  'arrow-pink': dynamic({
    loader: () => import('./assets/arrow-pink.svg'),
    ssr: false,
  }),
  'file-uploaded-check': dynamic({
    loader: () => import('./assets/file-uploaded-check.svg'),
    ssr: false,
  }),
  'exclamation-mark': dynamic({
    loader: () => import('./assets/exclamation-mark.svg'),
    ssr: false,
  }),
  'pdf-file': dynamic({
    loader: () => import('./assets/pdf-file.svg'),
    ssr: false,
  }),
  'home-pink': dynamic({
    loader: () => import('./assets/home-pink.svg'),
    ssr: false,
  }),
  star: dynamic({
    loader: () => import('./assets/star.svg'),
    ssr: false,
  }),
  eye: dynamic({
    loader: () => import('./assets/eye.svg'),
    ssr: false,
  }),
  eye_close: dynamic({
    loader: () => import('./assets/eye_close.svg'),
    ssr: false,
  }),
  copy: dynamic({
    loader: () => import('./assets/copy.svg'),
    ssr: false,
  }),
  'verify-access': dynamic({
    loader: () => import('./assets/verify-access.svg'),
  }),
  apartments: dynamic({
    loader: () => import('./assets/apartments.svg'),
    ssr: false,
  }),
  hotel: dynamic({
    loader: () => import('./assets/hotel.svg'),
    ssr: false,
  }),
  hostel: dynamic({
    loader: () => import('./assets/hostel.svg'),
    ssr: false,
  }),
  'guest-house': dynamic({
    loader: () => import('./assets/guest-house.svg'),
    ssr: false,
  }),
  'rest-base': dynamic({
    loader: () => import('./assets/rest-base.svg'),
    ssr: false,
  }),
  'country-house': dynamic({
    loader: () => import('./assets/country-house.svg'),
    ssr: false,
  }),
  'mobile-divider': dynamic({
    loader: () => import('./assets/mobile-divider.svg'),
    ssr: false,
  }),
  telegram: dynamic({
    loader: () => import('./assets/telegram.svg'),
    ssr: false,
  }),
  profile: dynamic({
    loader: () => import('./assets/profile.svg'),
    ssr: false,
  }),
  email: dynamic({
    loader: () => import('./assets/email.svg'),
    ssr: false,
  }),
  close: dynamic({
    loader: () => import('./assets/close.svg'),
    ssr: false,
  }),
};

export type IconName = keyof typeof icons;

interface IconProperties extends Omit<ComponentProps<'svg'>, 'name'> {
  name: IconName;
  width: NonNullable<ComponentProps<'svg'>['width']>;
  height: NonNullable<ComponentProps<'svg'>['height']>;
}

const Component: FC<IconProperties> = ({name, ...properties}) => {
  if (!(name in icons)) {
    throw new Error(
      `Icon ${name.toString()} does not exist in the icons/assets directory.`,
    );
  }

  const Selected = icons[name];

  return <Selected {...properties} />;
};

export const Icon = forwardRef<SVGSVGElement, IconProperties>(
  (properties, ref) => <Component ref={ref} {...properties} />,
);

Icon.displayName = 'IconComponent';
