import styled from '@emotion/styled';
import {ButtonLink} from 'library/components/button-link';
import {Headline} from 'library/components/headline';
import useTranslation from 'next-translate/useTranslation';
import {FC, useContext, useEffect, useState} from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import {HotelContext} from 'source/context/hotel';
import {useAppDispatch, useAppSelector} from 'source/store';
import {useDeviceDetection} from 'source/utilities/hooks/use-device-detection';
import {clientHeaders} from 'source/utilities/network/http';
import {HotelsRoomsDetailResponse} from 'types/api-scheme';
import {useRealtyIntegration} from 'source/utilities/hooks/use-is-realty-integration';
import {getRoomsList} from './network';
import {
  addRooms,
  reset,
  selectIsLastPage,
  selectRooms,
  setMeta,
  setRooms,
} from './store';
import {RoomCard} from './ui/room-card';

const Wrapper = styled.div`
  max-width: 1054px;
  margin: 0 auto;
  margin-top: 55px;
  padding: 0 20px;
  width: 100%;
`;

const Title = styled(Headline)`
  margin-bottom: 26px;
`;

const StyledButton = styled(ButtonLink)`
  width: 280px;
  margin-bottom: 45px;
  text-transform: uppercase;
  height: 58px;
  @media (max-width: 480px) {
    width: 100%;
  }
`;

const CardsWrapper = styled.ul`
  list-style: none;
  padding: 4px;
  --gap: 16px;
  --columns: 3;
  @media (max-width: 900px) {
    --columns: 2;
  }
  @media (max-width: 480px) {
    --columns: 1;
  }
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  gap: var(--gap);

  li {
    width: calc(
      (100% / var(--columns)) - var(--gap) + (var(--gap) / var(--columns))
    );
  }
`;

interface RoomsContentProps {
  rooms?: HotelsRoomsDetailResponse;
}

const Content: FC<RoomsContentProps> = ({rooms}) => {
  const [hotel] = useContext(HotelContext);
  const [page, setPage] = useState(2);
  const [loading, setLoading] = useState(false);
  const [hasRealtyIntegration] = useRealtyIntegration();

  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(reset());
    if (rooms) {
      dispatch(setMeta(rooms.meta));
      dispatch(setRooms(rooms.data));
    }
  }, []);

  const {t} = useTranslation('rooms');
  const mobile = useDeviceDetection('mobile');

  const roomsList = useAppSelector(selectRooms);
  const isLastPage = useAppSelector(selectIsLastPage);

  const fetchRooms = async () => {
    setLoading(true);
    if (hotel && hotel.id) {
      await getRoomsList(page, hotel.id, clientHeaders()).then((data) => {
        setPage(page + 1);
        setLoading(false);
        if (data) {
          const {data: rooms, meta} = data;
          dispatch(setMeta(meta));
          dispatch(addRooms(rooms));
        }
      });
    } else {
      setLoading(false);
    }
  };

  return (
    <Wrapper>
      <Title level={mobile ? 'S' : 'L'}>{t('title')}</Title>
      {!hasRealtyIntegration && (
        <StyledButton variant="primary" href={`/manage/${hotel?.id}/rooms/add`}>
          {t('add_room')}
        </StyledButton>
      )}

      {roomsList ? (
        <InfiniteScroll
          next={loading ? () => {} : fetchRooms}
          loader={null}
          dataLength={roomsList.length}
          hasMore={!isLastPage}
        >
          <CardsWrapper>
            {roomsList
              ? roomsList.map((room) => (
                  <li key={room.id}>
                    <RoomCard room={room} />
                  </li>
                ))
              : null}
          </CardsWrapper>
        </InfiniteScroll>
      ) : null}
    </Wrapper>
  );
};

export default Content;
