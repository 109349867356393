import styled from '@emotion/styled';
import {Text} from 'library/components/text';
import useTranslation from 'next-translate/useTranslation';
import {FC, useContext, useEffect, useRef} from 'react';
import {Content as ContentComponent} from 'source/components/content';
import {Icon} from 'source/components/icon';
import {Main} from 'source/components/main';
import {UtilitiesContext} from 'source/context/utilities';
import {useAppDispatch} from 'source/store';
import {useDeviceDetection} from 'source/utilities/hooks/use-device-detection';

import {
  reset,
  setExistingRequisites,
  setLegalForm,
  setRequisites,
  setRequisitesExists,
} from './store';
import {ExistingRequisites} from './ui/existing-requisites';
import {Invoice} from './ui/invoice';
import {RequisitesForm} from './ui/requisites-form';

const StyledContent = styled(ContentComponent)`
  width: 100%;
  max-width: 1050px;
  padding-top: 50px;
  margin: 0 auto;
  display: block;
`;

const HeaderTitle = styled(Text)`
  margin-bottom: 19px;
  display: flex;
  align-items: center;
  gap: 16px;
  @media (max-width: 650px) {
    flex-direction: column;
    align-items: start;
  }
`;

const HeaderSubtitleWrapper = styled.div`
  width: fit-content;
  padding: 20px;
  margin-bottom: 52px;
  letter-spacing: 0.03em;
  border-radius: 6px;
  box-shadow: 0 4px 20px ${({theme}) => theme.palette.cardShadowColor};
`;

const ContentWrapper = styled.div<{isMobile: boolean}>`
  display: flex;
  align-items: start;
  flex-direction: ${({isMobile}) => (isMobile ? 'column-reverse' : 'auto')};
`;

const RequisitesInfo = styled.div`
  width: 100%;
  max-width: 480px;
  box-sizing: border-box;
`;

interface ContentProps {
  serverRequisites?: Requisites | null;
  serverExistingRequisites?: ExistingRequisites;
}

const Content: FC<ContentProps> = ({
  serverRequisites,
  serverExistingRequisites,
}) => {
  const dispatch = useAppDispatch();
  const mobile = useDeviceDetection('mobile');
  const {t} = useTranslation('requisites');
  const titleRef = useRef<null | HTMLParagraphElement>(null);
  const [utilities] = useContext(UtilitiesContext);

  useEffect(() => {
    if (serverRequisites) {
      dispatch(setRequisites(serverRequisites));
      if (serverRequisites.id) {
        dispatch(setRequisitesExists(true));
      }
      if (serverRequisites.type) {
        dispatch(setLegalForm(serverRequisites.type.value));
      }
    }
    if (serverExistingRequisites) {
      dispatch(setExistingRequisites(serverExistingRequisites));
    }
    return () => {
      dispatch(reset());
    };
  }, []);

  return (
    <Main>
      <StyledContent>
        <HeaderTitle ref={titleRef} size={mobile ? 'boldS' : 'boldXXL'}>
          <Icon name="guard" height={36} width={36} />
          {t('headline')}
        </HeaderTitle>
        <HeaderSubtitleWrapper>
          <Text size="S">{t('subtitle')}</Text>
        </HeaderSubtitleWrapper>
        <ContentWrapper isMobile={mobile}>
          <RequisitesInfo>
            {serverExistingRequisites &&
            serverExistingRequisites.items.length > 0 ? (
              <ExistingRequisites serverRequisites={serverRequisites} />
            ) : null}
            <RequisitesForm ref={titleRef} />
          </RequisitesInfo>
          {utilities.windowWidth > 900 ? <Invoice /> : null}
        </ContentWrapper>
      </StyledContent>
    </Main>
  );
};

export default Content;
