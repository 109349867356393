import {DragEndEvent} from '@dnd-kit/core';
import {is} from 'ramda';
import {FC, useContext, useState} from 'react';
import {HotelContext} from 'source/context/hotel';
import {Dispatcher} from 'types/utilities';
import ActionButtons from './action-buttons';
import Header from './header';
import ImagesList from './images-list';

interface Props {
  setIsUploading: Dispatcher<boolean>;
  roomId?: number;
  selectedImages: number[];
  images?: Images;
  onSelectImage: (imageId: number) => void;
  onRemoveImage: (imageId: number) => void;
  onMoveImages: (
    images: Images,
    activeIndex: number,
    overIndex: number
  ) => void;
  onReorderImages: (
    activeId: number,
    preHotelImageId: number,
    images: Images,
    activeIndex: number,
    overIndex: number
  ) => void;
  maxPhotos: number;
  minPhotos: number;
  onMainImageChange: (imageId: number, url: string) => Promise<void>;
  onDeleteComplete: (imageId: number) => void;
  onDeleteImage: (
    imageId: number,
    onComplete: () => void,
    setLoading: Dispatcher<boolean>
  ) => void;
  isMainImage: (imageId: number, roomId?: number) => boolean;
  onSelect: (images: Images) => void;
  onUnselect: () => void;
  onDeleteSelectedComplete: () => void;
  onDeleteSelected: (
    selectedImages: number[],
    onComplete: () => void,
    setLoading: Dispatcher<boolean>
  ) => void;
}

const Gallery: FC<Props> = ({
  setIsUploading,
  images,
  selectedImages,
  onRemoveImage,
  onSelectImage,
  onMoveImages,
  onReorderImages,
  roomId,
  minPhotos,
  maxPhotos,
  onMainImageChange,
  isMainImage,
  onDeleteComplete,
  onDeleteImage,
  onDeleteSelected,
  onDeleteSelectedComplete,
  onSelect,
  onUnselect,
}) => {
  const [hotel] = useContext(HotelContext);
  const [loading, setLoading] = useState(false);

  const lengthImages = images?.length || 0;
  const leftUpload = lengthImages < minPhotos ? minPhotos - lengthImages : null;

  const onImageSelect = (image: Image) => {
    if (selectedImages.includes(image.id)) {
      onRemoveImage(image.id);
    } else {
      onSelectImage(image.id);
    }
  };

  const onImageDragEnd = (event: DragEndEvent) => {
    const {active, over} = event;
    if (
      images &&
      hotel?.id &&
      is(Number)(active?.id) &&
      is(Number)(over?.id) &&
      over?.id &&
      active.id !== over.id
    ) {
      const items = images.map(({id}) => id);
      const activeIndex = items.indexOf(active.id);
      const overIndex = items.indexOf(over.id);
      const preHotelImageId =
        activeIndex > overIndex
          ? images[overIndex - 1]?.id
          : images[overIndex]?.id;
      onMoveImages(images, activeIndex, overIndex);
      onReorderImages(
        active.id,
        preHotelImageId,
        images,
        activeIndex,
        overIndex
      );
    }
  };

  return (
    <>
      <Header minPhotos={leftUpload} />
      <ActionButtons
        isGalleryLoading={loading}
        maxCount={maxPhotos}
        images={images}
        setIsUploading={setIsUploading}
        setGalleryLoading={setLoading}
        selectedImages={selectedImages}
        onDeleteComplete={onDeleteSelectedComplete}
        onDelete={onDeleteSelected}
        onSelect={onSelect}
        onUnselect={onUnselect}
      />
      <ImagesList
        isGalleryLoading={loading}
        onImageSelect={onImageSelect}
        onImageDragEnd={onImageDragEnd}
        selectedImages={selectedImages}
        onDeleteComplete={onDeleteComplete}
        images={images}
        onDeleteImage={onDeleteImage}
        onMainImageChange={onMainImageChange}
        isMainImage={isMainImage}
        roomId={roomId}
      />
    </>
  );
};

export default Gallery;
